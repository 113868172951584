import React from "react";
import SearchResultsTemplate from "../../../templates/search-results-template";

const PropertySale = (props) => {

    const fullpath = props.location.pathname
    const location = props.location
    const fullpathone = props.location

    return (
        <React.Fragment>
            <SearchResultsTemplate 
                locationname="/properties/for-sale/" 
                location={location}
                pcategorytype="residential" 
                ptype="sales" 
                ptypetag="for-sale" 
                pstatustype="active" 
                // pstatustype= {['For Sale','Exchanged','Under Offer']}
                status="active"
                fullpathone={fullpathone} 
                fullpathname={fullpath}
            />
        </React.Fragment>
    )
}

export default PropertySale